import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/';

import { Config } from '../../config/config.config';
import { Asset, AssetAllocation,  CompanyConfig, EquipmentUsed, MaterialTransfer, PartyToPartyTransactionAdd, Progress, Project, WorkOrder } from 'src/app/interfaces/interfaces';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {


  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private config: Config
  ) { }
  addProject(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/project`,
      data,
      {}
    );
  }
  editProject(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/project`,
      data,
      {}
    );
  }
  editProjectStatus(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/project/status`,
      data,
      {}
    );
  }
  addTask(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/task`,
      data,
      {}
    );
  }
  editTask(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/task`,
      data,
      {}
    );
  }
  addDrawing(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/drawing`,
      data,
      {}
    );
  }
  editDrawing(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/drawing`,
      data,
      {}
    );
  }
  addExpense(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/expense`,
      data,
      {}
    );
  }
  editExpense(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/expense`,
      data,
      {}
    );
  }
  getTotalExpense(params?: HttpParams): Observable<any> {
    if (this.authService.hasAccess(this.authService.companyUserPolicy.View_all_transactions)) {
      return this.http.get(
        `${this.config.userEndpoint}/chart/expense/feature-wise`,
        { params }
      );
    } else {
      return null;
    }
  }
  getMaterialStock(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/materialstock`,
      { params }
    );
  }
  getWarehouseMaterialStock(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/warehouse/list/materialstock`,
      { params }
    );
  }
  getMaterialItem(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/materialitem`,
      { params }
    );
  }

  getMaterialReturnItem(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/materialreturn`,
      { params }
    );
  }

  getDetailMaterial(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialitem/${id}`,
      {}
    );
  }
  getDetailMaterialReturn(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialreturn/${id}`,
      {}
    );
  }
  deleteMaterialItem(id): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/materialitem/${id}`,
      {}
    );
  }
  getDetailMaterialPurchase(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialpurchase/${id}`,
      {}
    );
  }

  getDetailMaterialreturn(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialreturn/${id}`,
      {}
    );
  }


  deleteMaterialPurchase(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/materialpurchase/${id}`,
      {}
    );
  }

  deleteMaterialReturn(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/materialreturn/${id}`,
      {}
    );
  }


  deleteReimbursement(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/reimbursement/${id}`, {}
    )
  }
  getMaterial(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/material`,
      { params }
    );
  }
  getWarehouseMaterialList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/warehouse/list/material`,
      { params }
    );
  }

  editMaterialBulk(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/edit/material/purchase`,
      data,
      {}
    );
  }

  editMaterialReturnBulk(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/edit/material/return`,
      data,
      {}
    );
  }
  getMaterialStockDetail(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialstock/${id}`,
      {}
    );
  }

  getProjectMaterialList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/project/material`,
      { params }
    );
  }


  getProjectMaterialDetail(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/material/${id}`,
      {}
    );
  }
  deleteProjectMaterialDetail(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/material/${id}`,
      {}
    );
  }
  addMaterialItem(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/materialitem`,
      data,
      {}
    );
  }
  editMaterialItem(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialitem`,
      data,
      {}
    );
  }
  addMaterialStockBulk(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/bulk/add/materialstock`,
      data,
      {}
    );
  }
  addMaterialStock(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/materialstock`,
      data,
      {}
    );
  }
  editMaterialStock(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialstock`,
      data,
      {}
    );
  }
  addMaterialInventory(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/material`,
      data,
      {}
    );
  }
  editMaterialInventory(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/material`,
      data,
      {}
    );
  }
  addMaterialPurchase(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/materialpurchase`,
      data,
      {}
    );
  }
  addMaterialReturnPurchase(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/materialreturn`,
      data,
      {}
    );
  }
  getMaterialPurchasebyMaterial(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialpurchase-bymaterial/${id}`,
      {}
    );
  }
  editMaterialPurchase(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialpurchase`,
      data,
      {}
    );
  }
  editMaterialReturn(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialreturn`,
      data,
      {}
    );
  }
  editMaterial(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/material`,
      data,
      {}
    );
  }
  addHelpVideo(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/helpvideo`,
      data,
      {}
    );
  }
  editHelpVideo(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/helpvideo`,
      data,
      {}
    );
  }
  takeUserAccess(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/admin/user/access`,
      data,
      {}
    );
  }
  getPlatfomStats(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/platform/stats`,
      { params }
    );
  }
  getActionLogs(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/actionlog`,
      { params }
    );
  }
  addCashbookEntry(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/cashbookentry`,
      data,
      {}
    );
  }
  addCompanyCashbookPay(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/cashbook/company/pay`,
      data,
      {}
    );
  }
  addCompanyCashbookReceive(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/cashbook/company/receive`,
      data,
      {}
    );
  }
  editCashbookEntry(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/cashbookentry`,
      data,
      {}
    );
  }
  getCashbookEntry(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/cashbookentry/${id}`,
      {}
    );
  }
  deleteCashbookEntry(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/cashbookentry/${id}`,
      {}
    );
  }
  listInvoiceUnsettledCashbookEntry(params?: HttpParams) {
    return this.http.get(
      `${this.config.userEndpoint}/list/invoice-unsettled/cashbookentry`,
      { params }
    );
  }
  countInvoiceUnsettledCashbookEntry(params?: HttpParams) {
    return this.http.get(
      `${this.config.userEndpoint}/count/invoice-unsettled/cashbookentry`,
      { params }
    );
  }
  listInvoiceSettledCashbookEntry(params?: HttpParams) {
    return this.http.get(
      `${this.config.userEndpoint}/list/invoice-settled/cashbookentry`,
      { params }
    );
  }
  addInvoiceCashbookEntry(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/settle/invoice`,
      data,
      {}
    );
  }
  addPersonalReimbursementPay(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/reimbursement`,
      data,
      {}
    );
  }
  addPersonalCashbookPay(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/cashbook/me/pay`,
      data,
      {}
    );
  }
  addPersonalCashbookReceive(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/cashbook/me/receive`,
      data,
      {}
    );
  }
  // team member admin services

  deleteTeamMember(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/team-member/${id}`,
      {}
    );
  }

  getActionLogCSV(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/actionlog/csv`,
      { params }
    );
  }
  getUsersList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/users`,
      { params }
    );
  }
  inviteAgain(user_id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/invite/again/${user_id}`,
      {}
    );
  }
  generatePaymentReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/wallet/pdf/${project_id}`,
      { params }
    );
  }
  generatePaymentSummaryReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/wallet-summary/pdf/${project_id}`,
      { params }
    )
  }
  generateTradePaymentReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/cashbook/pdf/trade-wise/${project_id}`,
      { params }
    );
  }
  generateCategoryPaymentReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/cashbook/pdf/category-wise/${project_id}`,
      { params }
    );
  }
  generateCSVPaymentReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/cashbook-csv/${project_id}`,
      { params }
    );
  }
  generatePDFPaymentTransaction(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/party-transanction/${project_id}`,
      { params }
    );
  }
  generateEquipmentstock(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/equipmentstock/pdf/${project_id}`,
      { params }
    );
  }
  generatePDFPartyBalance(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/party-balance/${project_id}`,
      { params }
    );
  }
  generatePDFSalesInvoice(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/salesorderinvoice/pdf`,
      { params }
    );
  }
  generatePDFWorkorder(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/vendor/report/pdf/quotation`,
      { params }
    );
  }
  generatePDFWorkorderBoq(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/report/pdf/boq`,
      { params }
    );
  }
  generateTaskReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/dpr/pdf/${project_id}`,
      { params }
    );
  }
  generateAttendanceReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/attendance-pdfbydate/${project_id}`,
      { params }
    );
  }
  generateAttendanceOperation(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/attendance-operation/${project_id}`,
      { params }
    );
  }
  generateAttendanceSalary(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/attendance-salary/${project_id}`,
      { params }
    );
  }
  generateCSVAttendanceReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/attendance-csv/${project_id}`,
      { params }
    );
  }
  generateCSVMaterialReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/material-csv/${project_id}`,
      { params }
    );
  }
  generateMaterialReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/material-purchase-report/${project_id}`,
      { params }
    );
  }
  generateMaterialInventoryReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/material-pdfbydate/${project_id}`,
      { params }
    );
  }
  getCompnayUserList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/companyuser`,
      { params }
    );
  }
  getCompnayPartyList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/company-party`,
      { params }
    );
  }
  getCompnayTransactionList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/company/list/transaction`,
      { params }
    );
  }

  getProjectTransactions(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/transaction`,
      { params }
    );
  }

  getProjectAllTransactions(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/all/transaction`,
      { params }
    );
  }

  getProjectMyTransactions(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/my/transaction`,
      { params }
    );
  }

  editCompnayUserList(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyuser`,
      data,
      {}
    );
  }
  getPartyMemberList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/party-member`,
      { params }
    );
  }
  saveNewCompanyUser(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/companyuser`,
      data,
      {}
    );
  }
  deleteCompanyUser(id): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/companyuser/${id}`,
      {}
    );
  }
  saveNewUserRole(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/team-member`,
      data,
      {}
    );
  }

  getWorkforceStockList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/workforcestock`,
      { params }
    );
  }

  addWorkforcestock(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/workforcestock`,
      data,
      {}
    );
  }

  editWorkforcestock(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/workforcestock`,
      data,
      {}
    );
  }

  hideCompanyUser(id: string): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/hide/companyuser/${id}`,
      {}
    );
  }

  addAttendance(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/attendance`,
      data,
      {}
    );
  }

  editAttendance(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/attendance`,
      data,
      {}
    );
  }

  deleteAttendance(id): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/remove/attendance/${id}`,
      {}
    );
  }

  listOvertimeLatefine(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/overtime`,
      { params }
    );
  }

  addOvertimeLatefine(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/overtime`,
      data,
      {}
    );
  }

  deleteOvertimeLatefine(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/overtime/${id}`,
      {}
    );
  }

  editOvertime(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/overtime`,
      data,
      {}
    );
  }

  listAllowanceDeduction(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/allowance`,
      { params }
    );
  }
  addAllowanceDeduction(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/allowance`,
      data,
      {}
    );
  }
  editAllowanceDeduction(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/allowance`,
      data,
      {}
    );
  }
  deleteAllowanceDeduction(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/allowance/${id}`,
      {}
    );
  }

  getWorkforceList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/workforce`,
      { params }
    );
  }

  addWorkforce(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/workforce`,
      data,
      {}
    );
  }
  listUserStockforceDetails(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/attendance`,
      { params }
    );
  }

  deleteUserStockforce(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/remove/workforcestock/${id}`,
      {}
    );
  }

  //APIs for Category
  subcategoryList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/subcategory`,
      { params }
    );
  }

  transactionSubcategoryList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/transaction/subcategory`,
      { params }
    );
  }

  subcategoryDetails(id?: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/subcategory/${id}`,
      {}
    );
  }
  addSubcategory(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/subcategory`,
      data,
      {}
    );
  }

  // getCityStateJSON(){
  //   return this.http.get(
  //     `https://onsite.fra1.cdn.digitaloceanspaces.com/app_json/state_city_in.json`
  //   );
  // }

  editSubcategory(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/subcategory`,
      data,
      {}
    );
  }

  categoryList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/category`,
      { params }
    );
  }

  getTransactionCreators(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/transaction/creator/companyuser`,
      { params }
    );
  }

  // All search APIs
  getSearchedMaterial(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/search/materialstock`,
      { params }
    );
  }
  getSearchedPartyMember(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/search/party-member`,
      { params }
    );
  }

  getSearchedProject(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/search/project`,
      { params }
    );
  }
  setFeatureAccess(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/team-member/feature-access`,
      data,
      {}
    );
  }

  deleteProject(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/project/${id}`,
      {}
    );
  }

  pinProject(id: string): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/pin/project/${id}`,
      {}
    );
  }

  editCompany(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/company`,
      data,
      {}
    );
  }

  editCompanyBackLock(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/company/lock`,
      data,
      {}
    );
  }

  addCompanyBackLock(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/add/company/lock`,
      data,
      {}
    )
  }

  addCompanyBank(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/companybankaccount`,
      data,
      {}
    );
  }
  listCompanyBank(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/companybankaccount`,
      { params }
    );
  }

  editCompanyBank(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companybankaccount`,
      data,
      {}
    );
  }

  listUnsettledInvoice(params?: HttpParams, isCompanyLevel = false): Observable<any> {
    return this.http.get(
      !isCompanyLevel ? `${this.config.userEndpoint}/list/unsettled/invoice` : `${this.config.userEndpoint}/list/unsettled/invoice/companylevel`,
      { params }
    );
  }

  unsettleInvoice(invoce_id: string): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/unsettle/invoice/${invoce_id}`,
      {}
    );
  }

  listInvoice(data): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/invoice`,
      {}
    );
  }

  invoiceSettlement(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/settle/cashbooktransaction`,
      data,
      {}
    );
  }

  listCashbookSettledInvoice(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/cashbook-settled/invoice`,
      { params }
    );
  }

  addPartyEarning(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/partyearning`,
      data,
      {}
    );
  }
  getDetailPartyEarning(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/partyearning/${id}`,
      {}
    );
  }
  deletePartyEarning(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/partyearning/${id}`,
      {}
    );
  }
  editPartyEarning(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/partyearning`,
      data,
      {}
    );
  }
  duplicateProject(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/duplicate/project`,
      data,
      {}
    );
  }

  companyPaymentsReportCsv(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/company/payment/csv`,
      { params }
    );
  }

  companyPatryTransactionReportPdf(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/pdf/party-transanction`,
      { params }
    );
  }

  itemwiseMaterialPurchaseReportCsv(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/company/itemwise-purchase/csv`,
      { params }
    );
  }
  purchaseExpenseReportCsv(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/company/purchase-expense/csv`,
      { params }
    );
  }
  companyProjectSummaryReportCsv(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/company/projects/csv`,
      { params }
    );
  }
  companyWorkforceSummaryReportCsv(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/company/workforcestock/csv`,
      { params }
    );
  }
  companySalesOrderInvoiceReportCsv(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/sales-order-invoice/csv`,
      { params }
    );
  }
  gstr1ReportCsv(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/invoice-tax-rate-wise/csv`,
      { params }
    );
  }
  monthlySalaryPdf(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/pdf/monthly-salary`,
      { params }
    );
  }
  addRazorpayOrder(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/razorpay-order`,
      data,
      {}
    );
  }
  addRazorpaySubscription(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/razorpay-subscription`,
      data,
      {}
    );
  }

  getSubscriptionPlanList(): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/subscription-plan`
    )
  }

  getCompanyParty(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/companyuser/${id}`,
      // {params}

    )
  }

  detailPartyMember(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/party-member`,
      { params })
  }

  getStaffLedger(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/staff-ledger/by-date-range`,
      { params })
  }

  getSearchedLibraryMaterial(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/materialitem`,
      { params }
    )
  }

  // duplicate
  // getSearchedLibraryTrade(params: HttpParams): Observable<any> {
  //   return this.http.get(
  //     `${this.config.userEndpoint}/list/subcategory`,
  //     { params }
  //   )
  // }

  editCompanyUser(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyuser/role`,
      data,
      {}
    );
  }

  listCompanyRole(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/companyrole`, { params })
  }

  getCompanyProjectStatus(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/stats/company/project`, { params })
  }
  getCompanyDetails(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/company/${id}`
    );
  }
  getReimbursementDetails(id?: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/reimbursement/${id}`,)
  }
  editReimbursement(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/reimbursement`,
      data,
      {}
    );
  }
  getAllPendingRejectedStatus(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/all/transaction`, { params })
  }
  getCompanyLevelAllPendingTransactions(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/approval/transaction/companylevel`, { params })
  }
  getPendingTransactionCount(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/transaction/stats/approval/count`, { params })
  }
  approveRejectCashbook(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/cashbook/approval`, data, {})
  }
  updateCashbookApprovalComment(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/update/cashbook/approval/comment`, data, {})
  }
  updateReimbursementApprovalComment(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/update/reimbursement/approval/comment`, data, {})
  }
  approveRejectReimbursement(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/reimbursement/approval`, data, {})
  }
  getCompanyRoleConfiguration(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/companyroleconfiguration`, { params })
  }
  editCompanyRoleConfiguration(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/companyroleconfiguration`, data, {})
  }
  setPrimaryCompanyBankAccount(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/set/companybankaccount/primary`, data, {})
  }
  deleteCompanyBankAccount(id: string): Observable<any> {
    return this.http.delete(`${this.config.userEndpoint}/delete/companybankaccount/${id}`, {})
  }

  getProjectImage(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/image`, { params })
  }

  getProjectAlbums(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/drawing`, { params })
  }
  editAlbum(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/drawing/prepend/image`,
      data,
      {}
    );
  }

  addAlbums(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/drawing`, data, {})
  }

  detailsSalesOrder(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/project/salesorder/${project_id}`, { params })
  }

  detailSalesOrderInvoice(id: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/salesorderinvoice/${id}`, {})
  }

  detailSalesOrderWithId(id: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/salesorder/${id}`, {})
  }

  salesOrderLeafCount(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/salesorder/leaf/billingactivity/count`,{params})
  }

  editSalesOrderInvoice(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/salesorderinvoice`,
      data,
      {}
    );
  }

  getListSalesOrderInvoice(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/salesorderinvoiceitem`, { params })
  }
  deleteSalesInvoiceOrder(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/salesorderinvoice/${id}`,
      {}
    );
  }

  createSalesOrder(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/salesorder`, data, {})
  }

  updateSalesOrderParty(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/salesorder/party`, data, {})
  }

  getDashboardData(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/dashboard/stats`, { params })
  }

  getCompanyBalance(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/company/balance/stats`, { params })
  }

  getCompanyPartyList(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/companyuser/ledger`, { params })
  }

  getCompanyPartyDetails(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/companyuserledger`, { params })
  }

  genrateInvoiceNumber(id: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/company/salesinvoice/prefix/${id}`, {});
  }

  editInvoiceNumber(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/salesorderinvoice/invoice-number`, data, {})
  }

  salesorderInvoice(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/salesorderinvoice`, data, {})
  }

  bulkSalesorderinvoiceitem(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/bulk/add/salesorderinvoiceitem`, data, {})
  }
  bulkEditSalesorderinvoiceitem(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/bulk/edit/salesorderinvoiceitem`, data, {})
  }
  saveBillingActivity(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/billingactivity`, data, {})
  }
  duplicateBillingActivity(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/duplicate/progress/billingactivity`, data, {})
  }
  getBillingActivityDetails(id):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/detail/billingactivity/${id}`,{})
  }
  getSuggestProgressAssignee(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/suggest/progress-assignee`, { params })
  }
  editBillingActivity(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/billingactivity`, data, {})
  }
  deleteBillingActivity(id): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/billingactivity/${id}`,
      {}
    );
  }

  getDetailWorkforcestock(id): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/workforcestock/${id}`, {})
  }

  getDetailAttendance(id): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/attendance/${id}`, {})
  }

  getMaterialReqPrefix(id): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/company/materialrequest/prefix/${id}`, {})
  }

  editMaterialReqPrefix(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/materialrequest/request-number`, data, {});
  }

  addMaterialRequest(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/materialrequest`, data, {});
  }

  editMaterialRequest(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/materialrequest`, data, {});
  }

  addBulkMaterialRequestItems(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/bulk/add/materialrequestitem`, data, {})
  }

  editBulkMaterialRequestItems(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/bulk/edit/materialrequestitem`, data, {})
  }

  addSalesLead(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/bookdemo/${data.id}`, data, {})
  }

  getPendingMaterialRequests(params): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/project/materialrequest/stats`, { params })
  }

  getPendingMaterials(params): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/materialrequestitem/projectlevel`, { params });
  }

  getCompanyLevelPendingMaterials(params): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/materialrequestitem/companylevel`, { params });
  }


  changePendingMaterialsStatus(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/materialrequestitem/status`, data, {})

  }

  getDetailedMaterialReq(id: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/materialrequest/${id}`, {})
  }

  deleteMaterialRequest(id: string): Observable<any> {
    return this.http.delete(`${this.config.userEndpoint}/delete/materialrequest/${id}`, {})
  }

  getSalesLeadToken(currentUser: any): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/salesleadtoken`, currentUser, {})
  }

  getBillingActivityList(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/billingactivity`, { params })
  }

  listVendorWorkOrder(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/vendor/list/workorder`,
      { params }
    );
  }
  getWorkorderDetails(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/vendor/detail/workorder/${id}`,
      {}
    );
  }
  getCompanyConfiguration(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/company/companyconfiguration/${id}`,
      {}
    );
  }
  editCompanyBusinessOverview(companyConfig: CompanyConfig): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyconfiguration/business-overview`,
      companyConfig,
      {}
    );
  }
  editCompanyConfigurationSignature(companyConfig: CompanyConfig): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyconfiguration/signature`,
      companyConfig,
      {}
    );
  }
  editCompanyConfigurationStamp(companyConfig: CompanyConfig): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyconfiguration/stamp`,
      companyConfig,
      {}
    );
  }
  addVendorWorkOrder(wordOrder: WorkOrder): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/vendor/add/workorder`,
      wordOrder,
      {}
    );
  }
  editVendorWorkOrder(wordOrder: WorkOrder): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/vendor/edit/workorder`,
      wordOrder,
      {}
    );
  }

  editVendorWorkOrderNumber(wordOrder: WorkOrder): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/vendor/edit/workorder/quotion-number`,
      wordOrder,
      {}
    );
  }

  deleteWorkorder(id): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/workorder/${id}`,
      {}
    );
  }
  duplicateQuotation(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/duplicate/quotation`,
      data,
      {}
    );
  }

  getProgressOrder(project_id): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/project/progressorder/${project_id}`,
      {}
    );
  }

  getProgressStats(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/stats/progress/billingactivity`, { params }
    )
  }

  getProgressTaskList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/progress/billingactivity`, { params }
    )
  }

  getProgressTaskListRoot(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/progress/root/billingactivity`, { params }
    )
  }

  getProgressAssigneeList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/progress-assignee`, { params }
    )
  }

  getDetailBillingActivity(id): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/billingactivity/${id}`, {}
    )
  }

  getProgressChatList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/progresschat`, { params }
    )
  }

  addProgressChatComment(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/progresschat/comment`, data, {}
    )
  }

  addUpdateProgress(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/billingprogresshistory`, data, {}
    )
  }

  deleteProgressHistory(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/billingprogresshistory/${id}`, {}
    )
  }

  editUpdateProgress(data: any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/billingprogresshistory`, data, {}
    )
  }

  addProgressImage(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/progresschat/image`, data, {}
    )
  }
  listCompanyrole(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/companyrole`,
      { params }
    );
  }
  getMaterialReceivedPartywiseCount(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/material/received/party-wise/count`,
      { params }
    );
  }
  getMaterialReceivedPartywise(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/material/received/party-wise`,
      { params }
    );
  }
  getRootFolders(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/project/root/folder`, { params }
    )
  }


  editOpeningBalance(data: any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyuser/openingbalance`, data, {}
    )
  }

  getCompanyUserBankAccount(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/companyuserbankaccount`, { params })
  }

  //Equipment Flow
  //Project Level
  getEquipmentStockList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/equipmentstock`, { params }
    );
  }
  addEquipmentStock(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/equipmentstock`, data, {}
    );
  }
  editEquipmentStock(data) {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/equipmentstock`, data, {}
    );
  }
  deleteEquipmentStock(id) {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/equipmentstock/${id}`, {}
    );
  }
  addEquipment(data) {
    return this.http.post(
      `${this.config.userEndpoint}/add/equipment`, data, {}
    );
  }
  editEquipment(data) {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/equipment`, data, {}
    );
  }
  deleteEquipment(id) {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/equipment/${id}`, {}
    );
  }
  getEquipmentList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/equipment`, { params }
    );
  }

  createFolder(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/companyfolder`, data, {}
    )
  }

  getCompanyFile(params: HttpParams): Observable<any> {
    return this.http.get(
      ` ${this.config.userEndpoint}/list/companyfile`, { params }
    )
  }

  getCompanyFileDetails(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/companyfile/${id}`, {}
    )
  }

  renameFileFolder(data: any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/rename/companyfile`, data, {}
    )
  }

  deleteFilesAndFolders(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/companyfile/${id}`, {}
    )
  }

  addEquipmentUsedEntry(data: EquipmentUsed): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/equipmentusedentry`, data, {}
    )
  }

  editEquipmentusedEntry(data: EquipmentUsed): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/equipmentusedentry`, data, {}
    )
  }

  getEquipmentChat(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/equipmentchat`, { params }
    )
  }

  getEquipmentstockDetails(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/equipmentstock/${id}`, {}
    )
  }

  getDetailMaterialItemEquipmentStock(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialItem/equipmentstock/${id}`, {}
    )
  }
  getRecentUsedMaterialItemDetail(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/recentusedmaterialitem`, { params }
    )
  }

  getProjectWarehouseList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/transferable/project`, { params }
    )
  }

  getMaterialInventoryItem(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/materialtransfer/list/materialstock`,
      { params }
    );
  }

  addMaterialTransfer(data: MaterialTransfer): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/materialtransfer/out`, data, {}
    )
  }

  editMaterialTransfer(data: MaterialTransfer): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialtransfer`, data, {}
    )
  }

  deleteMaterialTransfer(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/materialtransfer/${id}`, {}
    )
  }

  getDetailMaterialTransfer(params: HttpParams, id: string) {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialtransfer/${id}`, { params }
    )
  }

  getWarehouseById(id): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/warehouse/${id}`,
      {}
    );
  }

  addWarehouse(data: Project): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/warehouse`, data, {}
    )
  }
  editWarehouse(data: Project): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/warehouse`, data, {}
    )
  }

  editOpeningStock(data: any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/openingstock`, data, {}
    )
  }
  editEstimatedQuantity(data:any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialstock/estimatedquantity`, data, {}
    )
  }

  removeMaterilStock(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/remove/materialstock/${id}`, {}
    )
  }

  editCompanyTerms(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/company/terms`, data, {}
    )
  }

  companyStats(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/company/actionable/stats`, { params }
    )
  }


  getSubConWorkOrderProjectLevelList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/subcon/list/workorder/projectlevel`, { params }
    )
  }

  getSubConWorkOrderList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/subcon/list/workorder`, { params }
    )
  }


  addSubConWorkOrder(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/subcon/add/workorder`, data, {}
    )
  }

  editSubConWorkOrder(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/subcon/edit/workorder`, data, {}
    )
  }

  editSubConWorkOrderQutaionNumber(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/subcon/edit/workorder/quotion-number`, data, {}
    )
  }

  editSubConExpenseInvoiceNumber(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/subconexpense/invoice-number`, data, {}
    )
  }

  getWorkOrderDetails(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/subcon/detail/workorder/${id}`, {}
    )
  }

  getSubConWorkOrderBillingActivity(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/subcon/list/billingactivity`, { params }
    )
  }

  getSubConBillingActivityDetails(id): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/billingactivity/${id}`, {}
    )
  }

  getSubConBillingActivityProgressHistory(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/activity-billingprogresshistory`, { params }
    )
  }

  addSubconBillingActivityProgress(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/billingprogresshistory`, data, {}
    )
  }

  listSubconWorkorderPartyWise(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/subcon/list/workorder/party-wise`, { params }
    )
  }

  getSubconWorkorderDetails(id): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/subcon/detail/workorder/${id}`,
      {}
    );
  }

  listSubconBillingActivity(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/subcon/list/billingactivity`, { params }
    )
  }

  saveSubconBillingActivity(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/subcon/add/billingactivity`, data, {})
  }

  editSubconBillingActivity(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/billingactivity`, data, {})
  }

  genrateSubconExpenseNumber(id: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/company/subconexpense/prefix/${id}`, {});
  }

  detailSubconExpense(id: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/subconexpense/${id}`, {})
  }

  getListSubconExpenseItems(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/subconexpenseitem`, { params })
  }

  addSubconExpense(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/subconexpense`, data, {})
  }

  editSubconExpense(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/subconexpense`, data, {})
  }

  deleteSubconExpense(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/subconexpense/${id}`,
      {}
    );
  }

  bulkSubconExpenseitem(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/bulk/add/subconexpenseitem`, data, {})
  }

  bulkEditSubconExpenseitem(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/bulk/edit/subconexpenseitem`, data, {})
  }

  getDetailSubconExpense(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/subconexpense/${id}`,
      {}
    );
  }

  listSubconExpenseItems(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/subconexpenseitem`, { params });
  }
  
  deleteSubconBillingActivity(id: string): Observable<any> {
    return this.http.delete(`${this.config.userEndpoint}/subcon/delete/billingactivity/${id}`, {})
  }

  getWorkOrderExpenseList(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/subconexpense`, { params });
  }

  deleteSubconWorkOrder(id:string):Observable<any>{
    return this.http.delete(`${this.config.userEndpoint}/subcon/delete/workorder/${id}`, {})
  }

  changeTaskIndex(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/billingactivity/swap/index`, data, {})
  }

  getProjectList(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/all/project`, { params });
  }

  getTaskList(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/progress/billingactivity/projectlevel`, { params });
  }

  getPurchaseOrderList(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/purchaseorder`, { params });
  }

  addPurchaseOrder(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/purchaseorder`, data, {})
  }

  editPurchaseOrder(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/purchaseorder`, data, {})
  }

  addBulkPurchaseOrderItem(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/bulk/add/purchaseorderitem`, data, {})
  }

  editBulkPurchaseOrderItem(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/bulk/edit/purchaseorderitem`, data, {})
  }

  purchaseOrderDetailById(id: string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/detail/purchaseorder/${id}`, {});
  }

  purchaseOrderItemList(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/purchaseorderitem`, { params });
  }
  purchaseOrderItemListCount(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/purchaseorderitem/count`, { params });
  }

  getPoProjectList(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/purchaseorder/project`, {params})
  }

  getPoVendorList(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/purchaseorder/party`, {params})
  }

  getPOMaterialReqItem(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/purchaseorder/materialrequestitem`,{params})
  }

  addGRN(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/grn`, data, {})
  }

  editGRN(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/grn`, data, {})
  }

  addBulkGRNMaterialItem(data): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/bulk/add/material/grn`, data, {})
  }

  editBulkGRNMaterialItem(data): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/bulk/edit/material/grn`, data, {})
  }

  getGRNDetail(id?:string):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/detail/grn/${id}`)
  }

  editPurchaseOrderStatus(data:any):Observable<any>{
    return this.http.patch(`${this.config.userEndpoint}/edit/purchaseorder/status`, data, {})
  }

  editMaterialApprovalStatus(data:any, status:string):Observable<any>{
    return this.http.patch(`${this.config.userEndpoint}/edit/materialrequestitem/${status}`, data, {})
  }

  deletePurchaseOrder(id:string):Observable<any>{
    return this.http.delete(`${this.config.userEndpoint}/delete/purchaseorder/${id}`, {})
  }

  purchaseOrderItemStatus(status,data){
    return this.http.patch(`${this.config.userEndpoint}/edit/purchaseorderitem/${status}`, data, {})
  }

  deleteGRN(id:string):Observable<any>{
    return this.http.delete(`${this.config.userEndpoint}/delete/grn/${id}`, {})
  }

  getGRNMaterialPartywiseCount(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/material/received/party-wise/count`,
      { params }
    );
  }
  getGRNMaterialPartywise(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/material/received/party-wise`,
      { params }
    );
  }

  editPurchaseOrderInvoiceNumber(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/purchaseorder/po-number`, data, {}
    )
  }

  editPurchaseOrderItemStatus(data,status): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/purchaseorderitem/${status}`, data, {}
    )
  }

  closePurchaseOrder(id): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/purchaseorder/close/${id}`,{}
    )
  }


  createAssetType(data:any): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/assetitem`,data,{}
    )
  }

  editAssetType(data:any):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/assetitem`, data, {}
    )
  }

  deleteAssetType(id:string):Observable<any>{
    return this.http.delete(
      `${this.config.userEndpoint}/delete/assetitem/${id}`,{}
    )
  }


  getAssetItemList(params:HttpParams): Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/assetitem`, {params}
    )
  }

  createAsset(data): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/asset`, data, {}
    )
  }

  editAsset(data:Asset):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/asset`, data, {}
    )
  }

  editAssetSerialNumber(data:any):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/asset/prefix-sequence`, data, {}
    )
  }

  deleteAsset(id:string):Observable<any>{
    return this.http.delete(
      `${this.config.userEndpoint}/delete/asset/${id}`
    )
  }

  getAssetsList(params:HttpParams): Observable<any>{
     return this.http.get(
      `${this.config.userEndpoint}/list/asset`,{params}
     )
  }

  getAssetDetails(id:string): Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/detail/asset/${id}`, {}
    )
  }

  assignAsset(data:AssetAllocation): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/asset/allocate`, data, {}
    )
  }

  assetReturn(data:AssetAllocation):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/asset/return`, data, {}
    )
  }

  editAssetAllocation(data:AssetAllocation):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/asset/allocation`, data, {}
    )
  }

  getListAssetAllocation(params:HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/asset/allocation`, {params}
    )
  }

  getDetailedAssetAllocation(id:string):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/detail/asset/allocation/${id}`,{}
    )
  }
  
  assetTransfer(data:any):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/asset/transfer`,data,{}
    )
  }
  

  getAssetPrefix(id:string):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/company/asset/prefix/${id}`,{}
    )
  }

  generatePODetailPdf(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/purchaseorder/pdf`,
      { params }
    );
  }
  generatePDFSubconExpense(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/subcon_expense/pdf`,
      { params }
    );
  }
  generatePDFSubconWorkorder(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/subcon_workorder/pdf`,
      { params }
    );
  }
  generatePDFMaterialPurchase(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/material-purchase/pdf`,
      { params }
    );
  }

  getListProjectDashboard(params:HttpParams):Observable<any>{
    return this.http.get(
    `${this.config.userEndpoint}/list/dashboard/project`, {params}
    )
  }

  updateCompanyUserpreferences(data):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyuser/preference`,data,{}
    )
  } 


  getTodoList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/all/todo`,
      { params }
    );
  }
  getMyTodoList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/my/todo`,
      { params }
    );
  }

  addTodo(data:any):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/todo`,data,{}
    )
  }

  editTodo(data:any):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/todo`,data,{}
    )
  }
  
  deleteTodo(id:string):Observable<any>{
    return this.http.delete(`${this.config.userEndpoint}/delete/todo/${id}`, {})
  }

  duplicateTodo(data:any):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/duplicate/todo`,data,{}
    )
  }

  todoStatus(action: string, id: string):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/${action}/todo/${id}`,{},{}
    )
  } 

  getTodoDetail(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/todo/${id}`, {}
    )
  }

  getTodoChatList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/todochat`, { params }
    )
  }

  getTodoAssigneeList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/todo/assignee`, { params }
    )
  }

  getTodoProjectList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/todo/project`, { params }
    )
  }

  getTodoTaskList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/todo/progress/billingactivity`, { params }
    )
  }

  addTodoChatImage(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/todo/image`, data, {}
    )
  }

  addTodoComment(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/todo/comment`, data, {}
    )
  }

  detailTodo(id:string):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/detail/todo/${id}`,{}
    )
  }
  // addTodoComment(todoComment:{todo_id:string, notes:string}):Observable<any>{
  //   return this.http.post(
  //     `${this.config.userEndpoint}/add/todo/comment`, {todoComment}
  //   )
  // }

  addTodoImage(data:any):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/todo/image`, {data}
    )
  }

  projectPartyStatus(action: string, id: string):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/${action}/party-member/${id}`,{},{}
    )
  }

  leaveCompany(id:string): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/leave/company/${id}`,{},{}
    );
  }

  listDeductions(params: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/deductionitem`, {params});
  }

  createDeduction(data: any): Observable<any> {
    return this.http.post(`${this.config.userEndpoint}/add/deductionitem`, data, {});
  }

  editDeductionItem(data: any): Observable<any> {
    return this.http.patch(`${this.config.userEndpoint}/edit/deductionitem`, data, {});
  }

  getDetailDeductionItem(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/deductionitem/${id}`, {}
    )
  }

  deleteDeductionItem(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/deductionitem/${id}`, {}
    )
  }

  listSalesInvoiceDeductionEntries(params?: HttpParams) {
    return this.http.get(
      `${this.config.userEndpoint}/list/deductionitem/salesorderinvoice`,
      { params }
    );
  }

  addBulkDeductionEntry(data: any): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/bulk/add/deductionentry`, data, {}
    )
  }

  editBulkDeductionEntry(data: any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/edit/deductionentry`, data, {}
    )
  }

  getDetailDebitNote(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/debitnote/${id}`, {}
    )
  }
  deleteDebitNote(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/delete/debitnote/${id}`, {}
    )
  }

  getDetailDebitNoteItems(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/debitnoteitem`, {params}
    )
  }
  editDebitNote(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/debitnote`,
      data,
      {}
    )
  }
  addDebitNote(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/debitnote`,
      data,
      {}
    )
  }
  bulkEditDebitNoteItems(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/edit/debitnoteitem`,
      data,
      {}
    )
  }
  bulkAddDebitNoteItems(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/bulk/add/debitnoteitem`,
      data,
      {}
    )
  }

  partyToPartyPayment(data:PartyToPartyTransactionAdd): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/cashbook/p2p`,
      data,
      {}
    )
  }

  deductionEntryList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/deductionentry`,
      { params }
    );
  }

  getCustomFieldList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/customfield`,
      { params }
    );
  }

  getCompanyLevelTeamMemberList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/team-member/companylevel`,
      { params }
    );
  }

  editCustomField(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/edit/customfield`,data,{}
    )
  }

  companyUserProjectList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/companyuser/list/project`,
      { params }
    );
  }

  bulkAddTeamMembarToProject(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/bulk/project/add/team-member`,
      data,
      {}
    );
  }

  bulkRemoveTeamMemberFromProject(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/project/remove/team-member`,
      data,
      {}
    );
  }

  addServiceRateLibrary(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/servicerate`,
      data,
      {}
    );
  }

  getServiceRateLibraryList(params:HttpParams): Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/servicerate`,
      {params}
    )
  }

  getDetailServiceRateLibrary(id:string):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/detail/servicerate/${id}`,{}
    )
  }

  editServiceRateLibrary(data):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/servicerate`,data,{}
    )
  }

  deleteServiceRateLibrary(id:string):Observable<any>{
    return this.http.delete(
      `${this.config.userEndpoint}/delete/servicerate/${id}`,{}
    )
  }

  getServiceRateLibraryListComponent(params:HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/servicerate/costcomponent`,{params}
    );
  }

  bulkAddCostComponent(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/bulk/add/servicerate/costcomponent`,
      data,
      {}
    );
  }

  bulkEditCostComponent(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/edit/servicerate/costcomponent`,
      data,
      {}
    );
  }

  categorywiseServiceRate(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/servicerate/subcategory-wise`,
      { params }
    );
  }

  bulkAddBillingActivityServiceRateWise(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/bulk/add/billingactivity/servicerate-wise`,
      data,
      {}
    );
  }

  createSectionBillingActivity(data):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/section/billingactivity`,data,{}
    )
  }

  createLeafBillingActivity(data):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/leaf/billingactivity`,data,{}
    )
  }

  addBillingActivityCostComponent(data):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/bulk/add/billingactivity/costcomponent`,data,{}
    )
  }

  getSectionAndGroupBillingactivity(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/billingactivity/section-and-group`,
      { params }
    );
  }

  editBillingActivityCostComponent(data):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/bulk/edit/billingactivity/costcomponent`,data,{}
    )
  }

  allBillingActivityList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/billingactivity/all`,
      { params }
    );
  }

  editLeafBillingActivity(data: any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/leaf/billingactivity`,
      data
    );
  }

  editSectionBillingActivity(data: any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/section/billingactivity`,
      data
    );
  }

  billingActivityCostComponent(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/billingactivity/costcomponent`, {params}
    )
  }

    listSalesOrder(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/salesorder`, {params}
    )
  }


  duplicateQuotationBillingActivity(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/duplicate/multilevel/billingactivity`,
      data, {}
    );
  }

  deleteQuotationBillingActivity(id:string):Observable<any>{
    return this.http.delete(`${this.config.userEndpoint}/delete/quotation/billingactivity/${id}`, {})
  }
  getSiBoqList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/salesorder/list/party-wise`,
      { params }
    );
  }

  getSiLeafBillingActivityList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/salesorder/leaf/billingactivity`,
      { params }
    );
  }


  duplicateSalesOrderBillingActivity(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/duplicate/salesorder/selected/billingactivity`,
      data, {}
    );
  }

  editSaledOrder(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/salesorder`,
      data, {}
    );
  }

  editPartyCustomField(data):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyuser/customfield`,data,{}
    )
  }

  removeCompanyUser(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/remove/companyuser/access`,
      data, {}
    );
  }



}
