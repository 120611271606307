import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { CompanyUser } from 'src/app/interfaces/interfaces';

interface GstDetails {
  gstin?: string;
  state_of_supply?: string;
  legal_business_name?: string;
  billing_address?: string
}

interface Parent {
  companyUser?: CompanyUser
}

@Component({
  selector: 'app-party-gst',
  templateUrl: './party-gst.component.html',
  styleUrls: ['./party-gst.component.css']
})
export class PartyGstComponent implements OnInit {
  @Input() parent: Parent = {
    companyUser: {} as CompanyUser
  }
  public gst_details = {} as GstDetails;

  constructor(
    public ngbOffActiveCanvas: NgbActiveOffcanvas,
    private notificationService: NotificationsService,
    private adminService: AdminService,
    public authService:AuthService
  ) {
    //
  }

  ngOnInit(): void {
    this.gst_details.gstin = this.parent.companyUser.gstin || '';
    this.gst_details.billing_address = this.parent.companyUser.billing_address || '';
    this.gst_details.legal_business_name = this.parent.companyUser.legal_business_name || '';
    this.gst_details.state_of_supply = this.parent.companyUser.state_of_supply || '';
  }

  save() {
    if (this.parent.companyUser.id) {
      this.parent.companyUser.gstin = this.gst_details.gstin;
      this.parent.companyUser.billing_address = this.gst_details.billing_address;
      this.parent.companyUser.legal_business_name = this.gst_details.legal_business_name;
      this.parent.companyUser.state_of_supply = this.gst_details.state_of_supply;
      this.adminService.editCompnayUserList(this.parent.companyUser).subscribe({
        next: res => this.ngbOffActiveCanvas.close(res),
        error: err =>console.log(err)
      })
    } else {
      this.ngbOffActiveCanvas.close(this.gst_details);
    }
  }
}
