<div class="custom-modal py-4 w-100 d-flex flex-column gap-2">

    <div class="w-100 d-flex justify-content-between px-4">
        <div class="d-flex flex-column align-items-start">
            <span class="f-18">Set Default Values</span>
            <div ngbDropdown>
                <button ngbDropdownToggle class="text-btn btn f-12 p-0">
                    {{customFieldTypes?customFieldTypes:'Select Type'}}
                </button>
                <div ngbDropdownMenu>
                    <button (click)="selectCustomFieldType('po'); customFieldTypes='Purchase Order'" ngbDropdownItem
                        class="btn f-12 text-btn" type="button">Purchase Order</button>
                    <span class="f-12 fw-bold mx-3 my-2">Party Type</span>
                    <button (click)="selectCustomFieldType('client_party'); customFieldTypes='Client'" ngbDropdownItem
                        class="btn f-12 text-btn" type="button">Client</button>
                    <button (click)="selectCustomFieldType('staff_party'); customFieldTypes='Staff'" ngbDropdownItem
                        class="btn f-12 text-btn" type="button">Staff </button>
                    <button (click)="selectCustomFieldType('labour_party'); customFieldTypes='Labour'" ngbDropdownItem
                        class="btn f-12 text-btn" type="button">Labour</button>
                    <button (click)="selectCustomFieldType('subcontractor_party'); customFieldTypes='Vendor'"
                        ngbDropdownItem class="btn f-12 text-btn" type="button">Vendor</button>
                </div>

            </div>
        </div>
        <button *ngIf="authService.session.profile.admin" (click)="createNewCustomField()" style="height: fit-content;"
            type="button" class="btn save-btn f-12 ">+ New Custom
            Field</button>
    </div>
    <div class="modal-body px-4 d-flex flex-column">
        <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll()"
            [scrollWindow]="false" [infiniteScrollDisabled]="valueStack.page.next_url == ''"
            style="flex-grow: 1; overflow-x: auto; scroll-behavior: smooth; overscroll-behavior: contain; border-radius: 8px 8px 0px 0px;"
            class="value-table-wrapper border">
            <div class="value-table w-100  d-flex flex-column">
                <div class="value-table-header w-100 d-flex align-items-center gap-1">
                    <div class="w-30 d-flex align-items-center justify-content-start">
                        <span style="padding-left: 12px;" class="f-10 disabled-font">Field Name</span>
                    </div>
                    <div class="w-10 d-flex align-items-center justify-content-center">
                        <span class="f-10 disabled-font">Data Type</span>
                    </div>
                    <div class="w-10 d-flex align-items-center justify-content-center">
                        <span class="f-10 disabled-font">Set Default</span>
                    </div>
                    <div class="w-40 d-flex align-items-center justify-content-center">
                        <span class="f-10 disabled-font">Default Value</span>
                    </div>

                    <div class="w-10 ">

                    </div>
                </div>
                <div class="value-table-body w-100 d-flex flex-column ">

                    <div *ngFor="let customField of customFieldsList" class="w-100 d-flex gap-1 border-bottom">
                        <div class="w-30 d-flex align-items-center justify-content-start border-right h-100 py-2">
                            <span style="padding-left: 12px;" class="f-12">{{customField.name | titlecase}}</span>
                        </div>
                        <div class="w-10 d-flex align-items-center justify-content-center border-right h-100 py-2">
                            <span class="f-12 disabled-font">{{customField.data_type | titlecase}}</span>
                        </div>
                        <div class="w-10 d-flex align-items-center justify-content-center border-right h-100 py-2">
                            <div class="form-switch d-flex justify-content-center">
                                <input style="text-align: start;" [(ngModel)]="customField.default_value_enabled"
                                    (ngModelChange)="customField.default_value_enabled = onCheckboxChange($event)"
                                    [checked]="customField.default_value_enabled === 1" class="form-check-input"
                                    type="checkbox" role="switch" name="purchaseorder">

                            </div>
                        </div>
                        <div [ngClass]="{'w-40':authService.session.profile.admin, 'w-50':!authService.session.profile.admin}"
                            class=" d-flex align-items-center justify-content-center border-right h-100 py-2">
                            <textarea *ngIf="customField.data_type === 'text'" tabindex="0"
                                [(ngModel)]="customField.default_value" autofocus class="f-12 custom-text-area" rows="3"
                                name="default-value" id=""></textarea>

                            <input *ngIf="customField.data_type === 'number'" type="text" tabindex="0"
                                [(ngModel)]="customField.default_value" (input)="customField.default_value = validatorService.numberFormat($event, {
                                type: 'string',
                                decimal: true,
                                digit: 2
                              })" s class="f-12 custom-input" name="default-value" id="">

                            <div *ngIf="customField.data_type === 'date'" (click)="openDatePicker(customField)"
                                style="width: 90%; height: fit-content !important;" class="custom-div d-flex p-1">
                                <span *ngIf="!customField.default_value" class="f-12 disabled-font">dd/mm/yyyy</span>
                                <span *ngIf="customField.default_value" class="f-12">{{customField.default_value
                                    | date:'dd/MM/yyyy'}}</span>
                                <ng-icon name="feather-calendar"
                                    class="disabled-fon d-flex align-items-center justify-content-center"
                                    style="transform: scale(0.9);"></ng-icon>
                            </div>

                        </div>

                        <div *ngIf="authService.session.profile.admin"
                            class="w-10 d-flex gap-3 align-items-center justify-content-center py-2">
                            <button (click)="createNewCustomField(customField)"
                                class="btn icon-btn edit-btn p-0 disabled-font">
                                <ng-icon style="transform: scale(0.9);" name="featherEdit3"
                                    class="d-flex align-items-center justify-content-center"></ng-icon>
                            </button>
                            <button (click)="deleteNewCustomField(customField.id)"
                                class="btn icon-btn edit-btn p-0 disabled-font">
                                <ng-icon style="transform: scale(0.9);" name="featherTrash"
                                    class="d-flex align-items-center justify-content-center"></ng-icon>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="!customFieldsList?.length"
                        class="w-100 h-100 d-flex justify-content-center align-items-center">
                        <span class="f-12 disabled-font">No Custom Fields Found</span>
                    </div>

                </div>
            </div>
        </div>

    </div>
    <div class="d-flex justify-content-end align-items-center gap-3 px-4">
        <button (click)="closeModal()" type="button" class="btn cancel-btn f-12 ">Cancel</button>
        <button (click)="editNewCustomField()" type="button" class="btn save-btn f-12 ">Save</button>
    </div>
</div>