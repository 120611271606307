<div class="newCustomfield">
    <div class="header py-3 px-4 d-flex justify-content-start border-bottom">
        <span style="font-weight: 500;" class="f-16">New Custom Field</span>
    </div>
    <div class="d-flex flex-column gap-4 px-4 pt-4">
        <div class="custom-input">
            <label for="labelName">Label Name</label>
            <input autofocus type="text" [(ngModel)]="customField.name" class="form-control f-12" id="labelName"
                placeholder="Label Name">
        </div>
        <div ngbDropdown>
            <div ngbDropdownToggle class="custom-div cursor">
                <label for="dataType">Data Type</label>
                <span *ngIf="!selectedDataType" class="f-12">Select Data Type</span>
                <span *ngIf="selectedDataType" class="f-12">{{selectedDataType | titlecase}}</span>
            </div>
            <div ngbDropdownMenu>
                <ng-container *ngFor="let dataType of dataTypes, ">
                    <span ngbDropdownItem (click)="selectDataType(dataType)" class="f-12 disabled-font">{{dataType |
                        titlecase}}</span>
                </ng-container>

            </div>
        </div>
    </div>
    <div class="d-flex justify-content-start p-4 gap-3">
        <button (click)="closeModal()" type="button" class="btn cancel-btn f-12 ">Cancel</button>
        <button (click)="saveNewCustomField()" type="button" class="btn save-btn f-12 ">Save</button>
    </div>
</div>