import { Component, ElementRef, ViewChild, AfterViewInit, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AdminService as AdminServiceAdmin } from 'src/app/admin/services/admin.service';
import { AdminService as AdminServiceCore } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NewCustomFieldComponent } from './components/new-custom-field/new-custom-field.component';
import { HttpParams } from '@angular/common/http';
import { CustomField } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/shared/services/validator.service';
import { CustomFieldDateComponent } from './components/custom-field-date/custom-field-date.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { ConfirmModalComponent } from 'src/app/shared/components/modal/confirm-modal/confirm-modal.component';

interface Source {
  company_id?: string;
}
@Component({
  selector: 'app-custom-field-modal',
  templateUrl: './custom-field-modal.component.html',
  styleUrls: ['./custom-field-modal.component.css']
})
export class CustomFieldModalComponent implements OnInit {
  @Input() source = {} as Source;
  // @ViewChild('dynamicTextarea') textareaElement!: ElementRef<HTMLTextAreaElement>;

  public customFieldsList: CustomField[];
  public model: NgbDateStruct;

  public loading = false;

  public valueStack = { page: { next_url: "" } };
  public busy = false;

  private company_id: string;
  public customFieldTypes = 'Purchase Order';
  constructor(
    private ngbModal: NgbModal,
    private ngbActiveModal: NgbActiveModal,
    public authService: AuthService,
    private admin_adminService:AdminServiceAdmin,
    private user_adminService: AdminServiceCore,
    private ngModal:NgbModal,
    public validatorService: ValidatorService,
    private utilService:UtilService
  ){
    //  this.model = this.calendar.getToday();
  }

  ngOnInit(): void {
    this.company_id = this.source.company_id || this.authService.session.company.id;
    this.getAdminCustomFields();
  }

getAdminCustomFields(type?:string){
  this.loading = true;
  let params = new HttpParams();
  params = params.append('company_id', this.company_id);
 if(type){ 
   params = params.append('custom_field_type', type);
 }else{
   params = params.append('custom_field_type', 'po');
 }


  const service = this.authService.session.profile.admin ? this.admin_adminService : this.user_adminService;
  service.getCustomFieldList(params).subscribe({
    next:(res) => {
      this.valueStack = res;
      this.customFieldsList = res.data;
      this.loading = false;
    },
    error:(err)=>{
      this.loading = false;
      console.error(err);
    }
  }); 
}


onCheckboxChange(event: any): number {
  return event ? 1 : 0;
}



  closeModal(){
    this.ngbActiveModal.close();
  }

  createNewCustomField(customField?: CustomField){
    let custom_type = '';

    switch (this.customFieldTypes) {
      case 'Purchase Order':
        custom_type = 'po';
        
        break;
      case 'Client':
        custom_type = 'client_party';
        break;
      case 'Staff':
        custom_type = 'staff_party';
        break;
      case 'Labour':
        custom_type = 'labour_party';
        break;
      case 'Vendor':
        custom_type = 'subcontractor_party';
        break;
      default:
        break;
    }
    const customFieldRef = this.ngModal.open(NewCustomFieldComponent,{
      size:'md'
    }); 
    customFieldRef.componentInstance.source = {customField: customField, company_id: this.company_id, type: custom_type};
    customFieldRef.result.then((onResult) => {
      this.getAdminCustomFields(custom_type);
    },(onReject)=>{
      //
    });
  }

  editNewCustomField(){
   
    const service = this.authService.session.profile.admin ? this.admin_adminService : this.user_adminService;
    service.editCustomField(this.customFieldsList).subscribe({
      next: (res) => {
        this.closeModal();
      },
      error:(err)=>{
        console.error(err);
      }
    })
  }

  deleteNewCustomField(id: string){
    
    const warning = this.ngbModal.open(ConfirmModalComponent);
    warning.componentInstance.data = { modalTitle: 'Warning', modalContent: 'Are you sure to delete' };
    warning.result.then((onResult)=>{
      this.admin_adminService.deleteCustomField(id).subscribe({
        next: (res) => {
          this.getAdminCustomFields();
        },
        error:(err)=>{
          console.error(err);
        }
      })
    }, (onReject)=>{
      //
    })
  }

  openDatePicker(customField: CustomField){
    const ngbModalRef = this.ngModal.open(CustomFieldDateComponent,{
      size:'md',
      centered: true,
      windowClass: 'custom-modal',
      backdropClass: 'custom-modal-backdropClass',
     

    });

    ngbModalRef.componentInstance.source = customField.default_value;

    ngbModalRef.result.then((onResult) => {
      customField.default_value = onResult.selectedDate;
    }, (onReject)=>{
     //
    })
  }

  onScroll() {
    const url = this.valueStack.page.next_url;
    if (!this.busy) {
     
      this.busy = true;
      this.utilService.getByUrl(url).subscribe(
        (res) => {
        
          this.valueStack = res;
          this.customFieldsList = this.customFieldsList.concat(res.data);
          this.busy = false;
        },
        (err) => {
        
          this.busy = false;
          this.valueStack = {
            page: { next_url: "" },
          };
        }
      );
    }
  }

  selectCustomFieldType(type:string){
    this.getAdminCustomFields(type);
  }

  
 

}
