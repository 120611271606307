export enum CompanyPartyType {
  Customer = 'Customer',
  Employee = 'employee',
  Labour = 'labour',
  MaterialSupplier = 'material supplier',
  LabourContractor = 'labour contractor',
  EquipmentSupplier = 'equipment supplier',
  SubContractor = 'sub contractor',
  Vendor = 'vendor'
}

