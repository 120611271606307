import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService as AdminServiceAdmin } from 'src/app/admin/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { CustomField } from 'src/app/interfaces/interfaces';

interface Source {
  customField: CustomField;
  company_id?: string;
  type?: string;
}

@Component({
  selector: 'app-new-custom-field',
  templateUrl: './new-custom-field.component.html',
  styleUrls: ['./new-custom-field.component.css']
})
export class NewCustomFieldComponent implements OnInit {
  @Input() source: Source;
  private company_id: string;
  public customField = {} as CustomField;
  public dataTypes = ['text', 'number', 'date'] ;
  public selectedDataType: string;
  constructor(
    private ngbActiveModal:NgbActiveModal,
    private admin_adminService:AdminServiceAdmin,
    private notificationService: NotificationsService,
  ){
   
  }
  ngOnInit(): void {
    this.company_id = this.source.company_id;
    if(this.source.customField?.id){
      this.customField = {...this.source.customField};
      this.selectedDataType = this.customField.data_type;
    }
  }

  createNewCustomField(){
    this.customField.company_id = this.company_id;
    this.customField.custom_field_type = this.source.type;
    
    this.admin_adminService.addCustomField(this.customField).subscribe({
      next: (res) => {
        console.log(res);
        this.ngbActiveModal.close();
      },
      error:(err)=>{
        //
      }
    })
  }

  editNewCustomField(){
    this.admin_adminService.editCustomField([this.customField]).subscribe({
      next: (res) => {
        this.ngbActiveModal.close();
      },
      error:(err)=>{
        console.error(err);
      }
    })
  }

  saveNewCustomField(){
    this.customField.data_type = this.selectedDataType;
    if(this.validateForm()){
      
      if(this.source.customField?.id){
        this.editNewCustomField();
      }else{
        this.createNewCustomField();
      }
     
    }
  }

  validateForm(): boolean {
     if(!this.customField.name){
      this.notificationService.initiate({title:'Label Name is required', type:toastTypes.error});
      return false;
     }else if(!this.selectedDataType){
      this.notificationService.initiate({title:'Data Type is required', type:toastTypes.error});
      return false;
     }
     return true;
  }
 
  selectDataType(dataType: string){
    this.selectedDataType = dataType;
  }

 
   
  closeModal(){
    this.ngbActiveModal.dismiss();
  }
}
