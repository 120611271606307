import { HttpParams } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";

import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexXAxis,
  ApexLegend,
  ApexGrid,
  ApexTooltip
} from "ng-apexcharts";
import { AuthService } from "src/app/core/services/auth.service";
import { Project } from "src/app/interfaces/interfaces";
import { ChartService } from "src/app/project/service/chart.service";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
  toolTip:ApexTooltip
};

@Component({
  selector: "app-financials",
  templateUrl: "./financials.component.html",
  styleUrls: ["./financials.component.css"],
})
export class FinancialsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  @Input() project = {} as Project;

  public chartOptions: Partial<ChartOptions>;
 


  public colors = ["#FE7503", '#FFBD00', '#9176FF', '#5E40D8'] as string[];
  public categories = [] as string[];
  public values = [] as number[];

  constructor(
    private chartService:ChartService,
    private authService: AuthService
  ) {
    //
  }


  ngOnInit(): void {
    // this.getFinancialHealthData();
    this.getChartExpenseFeatureWise();
    this.authService.project$.subscribe(res=>{
      const index = this.categories.indexOf('Project Value');
      if(index > -1) this.values[index] = this.authService.session.project.estimated_cost;
    })
   
  }

  generateChart(){
    this.chartOptions = {
      series: [
        {
          data: this.values,
        },
      ],
      toolTip:{
        x:{
          show:false
        },
        y: {
          formatter: function(value, { dataPointIndex, w}) {
            return  `${w.globals.labels[dataPointIndex]}`+` `+`${value}`
          },
        title: {
           
          formatter: function () {
            return ''
          }
        }
      }
    },

      chart: {
        height: 180,
        type: "bar",
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      

      colors: this.colors?this.colors:null,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
        
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },

      yaxis:{
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },

        labels: {
          style: {
            fontSize: "0px",
          },
        },

      },

      xaxis: {
        categories: this.categories,
        labels: {
          style: {
            fontSize: "0px",
          },
        },
      },
    };
  }

  // getFinancialHealthData(){
  //   let qp = new HttpParams();
  //   qp = qp.set('project_id', this.project.id);

  //   this.chartService.getFinancialHealth(qp).subscribe({
  //     next:res=>{
  //       res.data?.map(item=>{
  //         this.categories.push(item.label);
  //         this.values.push(item.value_y);
  //         this.generateChart();            
  //       })
  //     },
  //     error:err=>{
  //       console.log(err)
  //     }
  //   })
  // }

  getChartExpenseFeatureWise(){
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);

    this.chartService.getChartExpenseFeatureWise(qp).subscribe({
      next:res=>{
        // this.expenseBills = res?.data;
        // this.generateChart();
        res?.financialData.map(item=>{
          this.categories.push(item?.label);
          this.values.push(item?.value_y);
        })
        this.generateChart(); 
      },
      error:err=>{
        //
      }
    })

  }
  


}
